<template>

<div style="max-width: 800px">

  <div class="card-box">
    <h4>User Accounts</h4>
    <p>View all the user accounts on the platform</p>
    <CButton @click="loadPath('auth/user-accounts')" color="info" class="card-box-button">
      User Accounts
    </CButton>
  </div>

  <div class="card-box">
    <h4>Service Accounts</h4>
    <p>View all the service accounts on the platform</p>
    <CButton @click="loadPath('auth/service-accounts')" color="info" class="card-box-button">
      Service Accounts
    </CButton>
  </div>

  <div class="card-box">
    <h4>Client Accounts</h4>
    <p>View all the client accounts on the platform</p>
    <CButton @click="loadPath('auth/client-accounts')" color="info" class="card-box-button">
      Client Accounts
    </CButton>
  </div>

</div>
</template>

<script>
export default {
  name: 'Auth',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
